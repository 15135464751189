import axios from "axios";

interface ValidationError {
  errorMessage: string | string[];
  statusCode: number;
  error: string;
}
export class ErrorMessage {
  code?: string | number;
  message!: string;
  name!: string;
}

export const handleAPIError = (error: Error | unknown): ErrorMessage => {
  console.log("got error", error);
  if (axios.isAxiosError<ValidationError>(error)) {
    if (error.response) {
      return {
        message: error.response.data.errorMessage.toString(),
        code: error.response.data.statusCode,
        name: error.response.data.error,
      };
    } else {
      return {
        code: error?.code,
        message: error.message,
        name: error.name,
      };
    }
  } else if (error instanceof Error) {
    return {
      message: error.message,
      name: error.name,
    };
  } else if (typeof error === "string") {
    return {
      message: error,
      name: "Error",
    };
  } else
    return {
      name: "Unhandled error type",
      message: "Unknown error",
    };
};
