/* eslint-disable react-hooks/exhaustive-deps */
import AppNavbarLayout from "./components/layout/AppHeader";
import { PageTitleProvider } from "./contexts/PageTitleProvider";
import { RenderOnAuthenticated } from "./components/layout/RenderOnAuthenticated";
import { Welcome } from "./routes/home/Welcome";
import { useAuth } from "react-oidc-context";
import React from "react";
import { Toaster } from "react-hot-toast";

function App() {
  const auth = useAuth();

  React.useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      if (
        window.confirm(
          "You're about to be signed out due to inactivity. Press OK to continue"
        )
      ) {
        auth.signinSilent(auth.settings);
      }
    });
  }, [auth.events, auth.signinSilent]);

  React.useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      console.log("Access token expired. Attempting to sign you in...");
      auth.signoutRedirect();
    });
  }, [auth.events, auth.signinRedirect]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
    case "signoutSilent":
      return <div>SSigning you out...</div>;
  }
  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return !auth.isAuthenticated ? (
    <Welcome />
  ) : (
    <div>
      <PageTitleProvider>
        <AppNavbarLayout />
        <div className="container-fluid mb-2">
          <RenderOnAuthenticated />
        </div>
        <Toaster position="bottom-center" reverseOrder={true} />
      </PageTitleProvider>
    </div>
  );
}

export default App;
