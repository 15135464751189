import { FieldErrors, FieldValues } from "react-hook-form";
import { format } from "libphonenumber-js";
import { GetProjectsDto } from "../services/dtos/projects.dto";
import dayjs from "dayjs";

export function multiFilterListOfObjectsByKey<T extends Record<string, any>>(
  list: Array<T>,
  filters: string[],
  key: string
) {
  return list.filter((obj) => filters.includes(obj[key]));
}

export function formatDate(input: string | Date, pattern = "YYYY-MM") {
  return dayjs(input).format(pattern);
}

export function multiFilterListOfBooleansByKey(
  list: Array<GetProjectsDto>,
  filters: boolean[],
  key: string
) {
  return list.filter((obj) => obj.isCompleted === filters[0]);
}
// Filter by a string in a key
export const multiFilterListOfStringsByKey = <T>(
  list: T[],
  searchString: string,
  key: keyof T
): T[] => {
  if (!searchString) return list;
  return list.filter((item) => {
    const value = item[key];
    return (
      typeof value === "string" &&
      value.toLowerCase().includes(searchString.toLowerCase())
    );
  });
};

/**
 * This functions handles cases of errors in a field array.
 * It renames the nested array in format of: [errorKey].[index].[nestedKey]
 * Example: dealer.0.name
 *  */
export function getFormNamesFromErrors<T extends FieldValues>(
  errors: FieldErrors<T>
) {
  if (errors) {
    return Object.keys(errors)
      .map((key) => {
        let error = errors[key];
        if (Array.isArray(error)) {
          return error.map((it, idx) => {
            return `${key}.${idx}.${Object.keys(it)[0]}`;
          }, key);
        } else {
          return key;
        }
      })
      .flat();
  } else return [];
}

// export function formatPhoneNumberIntl<T>(apiDto: T, key: keyof T) {
//   const phoneNumber = apiDto[key] as string;
//   try {
//     apiDto[key] = format(phoneNumber, "INTERNATIONAL");
//   } catch (error) {
//     throw error;
//   }
// }

export function formatPhoneNumberIntl(phoneNumber: string) {
  return format(phoneNumber, "INTERNATIONAL");
}
