import { faSpinner, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "reactstrap";
import { BooleanInput } from "../../components/form/BooleanFormInput";
import { FormInput } from "../../components/form/FormInput";
import { FormSelect } from "../../components/form/FormSelect";
import { ReactFormCreatableSelect } from "../../components/form/ReactFormCreatableSelect";
import { SubmitButton } from "../../components/form/SubmitButton";
import { AlertDisplay } from "../../components/layout/AlertDisplay";
import { ErrorHandler } from "../../components/layout/ErrorDisplay";
import {
  Company,
  PROJECT_TYPES,
  PROJECT_VALUE_ESTIMATES,
  ProjectStage,
  ProjectStatus,
} from "../../constants";
import { handleAPIError } from "../../services/utils";
import { ProjectStages } from "../ProjectStages";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  CreateProjectFormData,
  CreateProjectSchema,
} from "./create-project.schema";
import { useScrollToError } from "../../hooks/useScrollToError";
import { useEffect } from "react";
import { GeolocationInfo } from "../../hooks/useGeolocation";
import { AxiosResponse } from "axios";

interface Props {
  onSubmit: (formData: CreateProjectFormData) => void;
  isEditMode?: boolean;
  responseError?: unknown;
  geolocationStatusInfo?: GeolocationInfo;
  initFormValues?: Partial<CreateProjectFormData>;
  handleOptionCreation: (x: string) => Promise<AxiosResponse<any, any>>;
  projectStages: ProjectStage[];
  updateProjectStage: React.Dispatch<any>;
}

export function ProjectForm({
  onSubmit,
  isEditMode = false,
  responseError = "",
  geolocationStatusInfo,
  initFormValues,
  handleOptionCreation,
  projectStages,
  updateProjectStage,
}: Props) {
  const { handleSubmit, control, formState, reset, watch } =
    useForm<CreateProjectFormData>({
      resolver: yupResolver(CreateProjectSchema),
      defaultValues: {
        address: "",
        architect: undefined,
        description: "",
        estimatedValue: "",
        leadContractor: undefined,
        name: "",
        siteManager: "",
        siteManagerContact: "",
        isCompleted: false,
        type: "",
      },
      mode: "onChange",
    });

  useEffect(() => {
    reset(initFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFormValues]);

  const startDate = watch("startDate");

  const { onError } = useScrollToError<CreateProjectFormData>(formState);

  function handleStageStatusChange(stageName: string, status: ProjectStatus) {
    updateProjectStage({
      type: "updateStageStatus",
      payload: {
        name: stageName,
        status,
      },
    });
  }

  function handleCompanyAddition(stageName: string, company: Company) {
    updateProjectStage({
      type: "addCompany",
      payload: {
        name: stageName,
        company,
      },
    });
  }
  function handleCompanyDeletion(stageName: string, companyID: string) {
    updateProjectStage({
      type: "deleteCompany",
      payload: {
        name: stageName,
        id: companyID,
      },
    });
  }

  const showLoading =
    formState.isValid &&
    (formState.isSubmitting || formState.isSubmitSuccessful) &&
    !responseError;

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)} className="m-2">
      <FormInput name={"name"} label={"Name"} control={control} />
      <FormSelect
        name={"type"}
        label={"Type"}
        control={control}
        options={PROJECT_TYPES}
      />
      <FormInput name={"description"} label={"Description"} control={control} />
      <FormSelect
        name={"estimatedValue"}
        label={"Estimated Value"}
        control={control}
        options={PROJECT_VALUE_ESTIMATES}
        helperText="If EUR amount is not known, KUM to convert local currency to EUR using current exchange rates"
      />
      <FormInput name={"address"} label={"Address"} control={control} />

      <ReactFormCreatableSelect
        name="leadContractor"
        isDisabled={false}
        label={"Lead Contractor"}
        control={control}
        handleCreation={handleOptionCreation}
        placeholder="Select Lead Contractor"
      />

      <FormInput
        name={"siteManager"}
        label={"Site Manager / Foreman - Name"}
        control={control}
      />

      <Row>
        <Col>
          <FormInput
            type="month"
            name="startDate"
            control={control}
            label="Start Date"
          />
        </Col>

        <Col>
          <FormInput
            type="month"
            name="endDate"
            control={control}
            label="End Date"
            min={startDate}
          />
        </Col>
      </Row>

      <FormInput
        name={"siteManagerContact"}
        label={"Site Manager / Foreman - Number"}
        control={control}
        type="tel"
        helperText="Start with country code: E.g: +254 followed by phone number"
      />

      <ReactFormCreatableSelect
        name="architect"
        isDisabled={false}
        label={"Architect"}
        control={control}
        handleCreation={handleOptionCreation}
        placeholder="Select Architect"
      />

      <h5 className="text-center fw-bold">Project Stages</h5>
      <ProjectStages
        projectStages={projectStages}
        onStageStatusChange={handleStageStatusChange}
        onStageCompanyDeletion={handleCompanyDeletion}
        onStageCompanyAddition={handleCompanyAddition}
      />

      <hr />

      {isEditMode ? (
        <BooleanInput
          name={"isCompleted"}
          label={"Is project complete?"}
          control={control}
        />
      ) : null}

      {responseError ? (
        <ErrorHandler error={handleAPIError(responseError)} />
      ) : null}

      <div className="d-grid py-3">
        <SubmitButton isLoading={showLoading} />
      </div>

      <AlertDisplay showModal={!!geolocationStatusInfo}>
        <p className="text-center">
          {geolocationStatusInfo?.status === "loading" ? (
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          ) : geolocationStatusInfo?.status === "denied" ? (
            <FontAwesomeIcon icon={faWarning} size="2x" color="red" />
          ) : null}
        </p>
        <p>{geolocationStatusInfo?.message}</p>
      </AlertDisplay>
    </Form>
  );
}
