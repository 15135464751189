import { Form } from "reactstrap";
import { FormInput } from "../../components/form/FormInput";
import { useForm } from "react-hook-form";
import {
  COUNTRIES,
  SignUpFormData,
  SignUpSchema,
} from "./schema/signup.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useScrollToError } from "../../hooks/useScrollToError";
import { SubmitButton } from "../../components/form/SubmitButton";
import { FormSelect } from "../../components/form/FormSelect";
import { useContext, useEffect } from "react";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import UserService from "../../services/user.service";
import { useAppUserAPI } from "../../services/app-user.service";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { handleAPIError } from "../../services/utils";

export function SignUp() {
  const navigate = useNavigate();
  const { formState, handleSubmit, control } = useForm<SignUpFormData>({
    resolver: yupResolver(SignUpSchema),
    defaultValues: {
      country: "",
      email: UserService.getUserEmail() || "",
      firstName: UserService.getUserFirstName() || "",
      lastName: UserService.getUserLastName() || "",
      appName: "Site Mgmt App",
      sub: UserService.getUserID(),
    },
  });
  const pageTitleDispatch = useContext(PageTitleDispatchContext);

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Sign Up",
      });
    }
  }, [pageTitleDispatch]);

  const { onError } = useScrollToError<SignUpFormData>(formState);

  const { useCreateAppUserMutation } = useAppUserAPI();
  const createAppUserMutation = useCreateAppUserMutation();

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        createAppUserMutation.mutate(data, {
          onSuccess: () => {
            toast.success("User info saved successfully");
            navigate("/");
          },
          onError: (e) => {
            const { message } = handleAPIError(e);
            console.log(message);
            toast.error(message);
          },
        });
      }, onError)}
    >
      <FormInput name={"firstName"} control={control} label="First Name" />
      <FormInput name={"lastName"} control={control} label="Last Name" />
      <FormInput
        name={"email"}
        control={control}
        label="Email"
        type="email"
        disabled
      />
      <FormSelect
        name={"country"}
        options={COUNTRIES.sort()}
        control={control}
        label="Country"
      />
      <div>
        <SubmitButton
          isLoading={
            formState.isValid &&
            formState.isSubmitting &&
            createAppUserMutation.isError
          }
        />
      </div>
    </Form>
  );
}
